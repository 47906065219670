import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { BranchListItem } from '../../api/articles/Branch';
import ChooseWarehouseList from './ChooseWarehouseList';

export interface ChooseWarehouseOnMapDialogProps
  extends Omit<StandardDivProps<ChooseWarehouseOnMapDialogClassKey>, 'onChange'> {
  branches: BranchListItem[];
  onChange: (value: BranchListItem) => void;
  onClose: () => void;
  open: boolean;
}

export type ChooseWarehouseOnMapDialogClassKey = 'root' | 'content' | 'map' | 'dialogContent' | 'whList';

const themeSettings = { name: 'ChooseWarehouseOnMapDialog' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ChooseWarehouseOnMapDialogClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'white',
        zIndex: 1300,
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s, visibility 0.3s',
        '&.open': {
          opacity: 1,
          visibility: 'visible',
        },
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
      },
      map: {
        width: '100vw',
        height: '100vh',
        '& .leaflet-container': {
          height: '100%',
          width: '100%',
        },
      },
      dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      whList: {
        marginRight: theme.spacing(2),
      },
    }),
  themeSettings,
);
declare const L: any;

const ChooseWarehouseOnMapDialog: React.FC<ChooseWarehouseOnMapDialogProps> = ({
  branches,
  onClose,
  onChange,
  open,
}) => {
  const classes = useStyles();
  const mapRef = useRef<HTMLDivElement | null>(null);
  const leafletMapRef = useRef<any>(null);

  useEffect(() => {
    if (!mapRef.current) return;

    if (!leafletMapRef.current) {
      const map = L.map(mapRef.current).setView([49.828501, 15.0308385], 8);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap contributors',
      }).addTo(map);

      branches.forEach((branch) => {
        const marker = L.marker([branch.latitude, branch.longitude]).addTo(map);
        const popupContent = `
          <div>
            <a href="${branch.href}">${branch.title}</a>
            <p>Adresa: ${branch.contactPostAddress}</p>
            <p>Tel: ${branch.contactPhone}</p>
            <p>E-mail: ${branch.contactEmail}</p>
            <button onClick="window.selectMapMarker('${branch.id}')">Vybrat pobočku</button>
          </div>
        `;
        marker.bindPopup(popupContent);
      });

      leafletMapRef.current = map;
    }

    window.selectMapMarker = (id) => {
      const branch = branches.find((b) => b.id === id);
      if (branch) {
        onChange(branch);
        onClose();
      }
    };

    return () => {
      window.selectMapMarker = undefined;
    };
  }, [branches, onChange, onClose]);

  return (
    <div className={clsx(classes.root, { open })}>
      <div className={classes.dialogContent}>
        <ChooseWarehouseList branches={branches} classes={{ root: classes.whList }} onChange={onChange} />
        <div id='map' ref={mapRef} className={classes.map}></div>
      </div>
      <button onClick={onClose}>Zavřít</button>
    </div>
  );
};

ChooseWarehouseOnMapDialog.displayName = 'ChooseWarehouseOnMapDialog';

export default withThemeProps(themeSettings)(ChooseWarehouseOnMapDialog);

declare global {
  interface Window {
    selectMapMarker?: (e: string) => void;
  }
}
